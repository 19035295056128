const theme = {
  colors: {
    text: "#4A4A4A",
    background: "#ffffff",
    primary: "#00793D",
    secondary: "#E20714",
    light: "#ffffff",
    dark: "#4A4A4A",
    darkGrey: "#5c5a56",
    lightGrey: "#f4f3ee",
  },
  breakpoints: ["40em", "52em", "82em"],
  fonts: {
    body:
      '"Montserrat", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Merriweather", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 192, 256, 512],
  fontSizes: [12, 14, 16, 18, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  sizes: {
    container: 1680,
  },
  radii: {
    none: "0",
    sm: ".5rem",
    default: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%)",
  },
  text: {
    default: {
      color: "text",
      fontSize: 3,
      lineHeight: "1.3",
      marginBottom: 4,
      fontWeight: 300,
    },
    heading: {
      fontSize: 7,
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "dark",
    },
    display: {
      fontSize: [5, 5, 6, 7],
      fontFamily: "heading",
      lineHeight: 1,
      fontWeight: "body",
      letterSpacing: "-0.7px",
      color: "dark",
    },
    lead: {
      fontSize: 4,
      color: "dark",
      textTransform: "uppercase",
      fontWeight: "body",
      lineHeight: "heading",
    },
    caption: {
      fontSize: 0,
      fontWeight: "bold",
      color: "dark",
    },
    date: {
      fontSize: 0,
      fontWeight: "bold",
      color: "light",
      textTransform: "uppercase",
    },
    h2: {
      fontSize: 5,
      color: "dark",
      fontWeight: 500,
      lineHeight: "heading",
      marginBottom: 3,
    },
    h3: {
      fontSize: 4,
      color: "dark",
      fontWeight: 500,
      lineHeight: "heading",
      marginBottom: 3,
    },
    h4: {
      fontSize: 3,
      color: "dark",
      fontWeight: 500,
      lineHeight: "heading",
    },
    h5: {
      fontSize: 3,
      color: "dark",
      fontWeight: 400,
      lineHeight: "heading",
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: 300,
      lineHeight: "heading",
    },
  },
  inputs: {
    primary: {
      border: "none",
      borderBottom: "1px solid",
      borderColor: "primary",
      borderRadius: "none"
    },
  },
  links: {},
  layout: {
    container: {
      maxWidth: 1280,
      padding: [3, 4],
    },
    small: {
      maxWidth: 1280,
      padding: [3, 4],
    },
    fullWidth: {
      maxWidth: "100%",
    },
  },
  styles: {
    root: {},
  },
}

export default theme
